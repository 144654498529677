import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import paragraphs from "lines-to-paragraphs"

const ResarchPage = ({ data }) => {
  const { name : fullName, research: { research }, cv, image } = data.contentfulPerson;
  return (
    <Layout fullName={ fullName } cv={ cv.file.url } profile= { image.file.url }>
      <SEO title="Research" />
      <div dangerouslySetInnerHTML={{ __html: paragraphs(research) }} />
    </Layout>
  );
};

export default ResarchPage

export const pageQuery = graphql`
{
  contentfulPerson(name: { eq: "Elysia Vaccarino" }) {
    id
    name
    research {
      research
    }
    cv {
      file {
        url
      }
    }
    image {
      file {
        url
      }  
    }
  }
}`